import React from 'react'
import Helmet from 'react-helmet'
import { graphql, withPrefix } from 'gatsby'
import Layout from '../layout'
import config from '../../data/SiteConfig'
import HomeLink from '../components/HomeLink/HomeLink'

import './index.scss'

class Index extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <div className='video-wrapper' style={{ position: 'fixed', zIndex: '0', width: '100%', height: '100%' }}>
          <video playsInline autoPlay muted loop poster="img/index_web_mobile_back.jpg" id="bgvid">
            <source src={withPrefix('home-video.mp4')} type="video/mp4" />
          </video>
        </div>
        <div className='index-container'>
          <Helmet title={config.siteTitle} />

          <div className='banner animated fadeInUp delay-1s'>
            <span>A</span>
            <span>V</span>
            <span>S</span>
            <span>T</span>
            <span>R</span>
            <span>A</span>
            <span>C</span>
            <span>T</span>
          </div>
          <div className='sub-banner animated fadeInUp delay-1s'>a film company</div>
          <div className='button-container'>
            <HomeLink hoverColor='mint' className='animated fadeIn delay-2s' to='/about'>ABOUT</HomeLink>
            <HomeLink hoverColor='yellow' className='animated fadeIn delay-2s' to='/collection'>THE COLLECTION</HomeLink>
            {/* <HomeLink live="fvfOEuRLCoc" hoverColor='red' className='animated fadeIn delay-2s' to='/collection'>PIZZA GIRL</HomeLink> */}
            <HomeLink hoverColor='orange' className='animated fadeIn delay-2s' to='https://paypal.me/avstractofficial'>DONATE</HomeLink>
            <HomeLink hoverColor='teal' className='animated fadeIn delay-2s' to='/find_us'>FIND US</HomeLink>
          </div>

        </div>
      </Layout>
    )
  }
}

export default Index
