import React, { Component } from "react"
import { Link } from 'gatsby'
import './HomeLink.scss'

class HomeLink extends Component {
  constructor (props) {
    super(props)
    this.state = { to: props.to, live: props.live }
  }

  render () {
    const { children } = this.props
    return (
      <div className={`home-link ${this.props.className} ${this.state.live ? 'live': ''}`}>
        { 
          this.state.to && this.state.to[0] !== '/'
            ? <a href={this.state.to} className={`link hover-${this.props.hoverColor}`} target="_blank">{children}</a>
            : <Link className={`link hover-${this.props.hoverColor}`} to={this.state.to} state={this.state.live ? { videoId: this.state.live } : {}}>{children}</Link>
        }
      </div>
    )
  }
}

export default HomeLink
