import React from "react"
import './AnimatedBars.scss'

class AnimatedBars extends React.Component {
  render () {
    return (
      <div className={`animated-bars ${this.props.className}`} onClick={this.props.onClick}>
        <span />
      </div>
    )
  }
}

export default AnimatedBars
