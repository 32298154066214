import 'animate.css/animate.min.css'
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { fab } from '@fortawesome/free-brands-svg-icons'
// import { faCoffee, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { withPrefix } from 'gatsby'
import React from "react"
import Helmet from "react-helmet"
import config from "../../data/SiteConfig"
import SideBar from '../components/SideBar/SideBar'
import GlobalStyles from './globalStyles'
import "./index.scss"

import favicon from '../../static/favicon.png'

// library.add(faCoffee, faEnvelope, fab)

export default class MainLayout extends React.Component {
  getLocalTitle () {
    function capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
    const pathPrefix = config.pathPrefix ? config.pathPrefix : "/"
    const currentPath = this.props.location.pathname
      .replace(pathPrefix, "")
      .replace("/", "")
    this.currentPath = currentPath
    let title = ""
    if (currentPath === "") {
      title = "Home"
    } else if (currentPath === "tags/") {
      title = "Tags"
    } else if (currentPath === "categories/") {
      title = "Categories"
    } else if (currentPath === "about/") {
      title = "About"
    } else if (currentPath.indexOf("posts")) {
      title = "Article"
    } else if (currentPath.indexOf("tags/")) {
      const tag = currentPath
        .replace("tags/", "")
        .replace("/", "")
        .replace("-", " ")
      title = `Tagged in ${capitalize(tag)}`
    } else if (currentPath.indexOf("categories/")) {
      const category = currentPath
        .replace("categories/", "")
        .replace("/", "")
        .replace("-", " ")
      title = `${capitalize(category)}`
    }
    return title
  }

  render () {
    const { children } = this.props
    return (
      <div className="index-layout">
        <GlobalStyles />
        <Helmet
          link={[
            { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` },
          ]}
        >
          <title>{`${config.siteTitle} |  ${this.getLocalTitle()}`}</title>
          <meta name="description" content={config.siteDescription} />
          <meta property="og:title" content="Avstract" />
          <meta property="og:url" content="https://avstract.com" />
          <meta property="og:type" content="website" />
          <meta property="og:description" content="Creating an audio and video escape through absurd films people can't look away from." />
          <meta property="description" content="Creating an audio and video escape through absurd films people can't look away from." />
          <meta property="og:image" content={withPrefix('avstract-initials.jpg')} />
          <script src="https://use.fontawesome.com/b7d7f74459.js"></script>
        </Helmet>
        {children}
      </div>
    )
  }
}
