import React, { Component } from "react"
import "./SideBar.scss"
import AnimatedBars from "../AnimatedBars/AnimatedBars"

class SideBar extends Component {
  constructor (props) {
    super(props)
    this.state = { menuOpen: false }
  }

  render () {
    return (
      <div className={`side-bar ${this.props.className} ${this.state.menuOpen ? 'active' : ''}`}>
        <AnimatedBars
          className={`toggle ${this.state.menuOpen ? 'active' : ''}`}
          onClick={() => this.setState({ menuOpen: !this.state.menuOpen })}
        />
        <div className="pull-out-nav">
          <nav className="sidebar">
            <ul>
              <li><a className="hover-mint" href="/">Home</a></li>
              <li><a className="hover-yellow" href="/about">About</a></li>
              <li><a className="hover-orange" href="/collection">The Collection</a></li>
              <li><a className="hover-red" href="/find_us">Find Us</a></li>
            </ul>
          </nav>
        </div>
      </div>
    )
  }
}

export default SideBar
