import { createGlobalStyle } from 'styled-components'
import BebasNeueRegTTF from '../fonts/bebasneue_reg.ttf'
import GillSansMTCondensed from '../fonts/gill_sans_mt_condensed.ttf'

export default createGlobalStyle`
  @font-face {
    font-family: 'Bebas-Neue';
    font-style: normal;
    font-weight: normal;
    src: url('${BebasNeueRegTTF}') format('truetype');
  }

  @font-face {
    font-family: 'Gill-Sans-MT-Condensed';
    font-style: normal;
    font-weight: normal;
    src: url('${GillSansMTCondensed}') format('truetype');
  }
`